.customModal {
  .ant-modal {
    &-header {
      color: rgba(0, 0, 0, 0.88);
      background: #E30613 !important; /* Apply the background color with !important */
      border-radius: 8px 8px 8px 8px;
      margin-bottom: 8px;
      padding: 15px;
      height: 50px;
      color: white;
      border-bottom: none;
    }

    &-content {
      position: relative;
      background-color: #ffffff;
      background-clip: padding-box;
      border: 0;
      border-radius: 8px;
      box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
      pointer-events: auto;
      padding: 0px 0px;
    }

    &-close {
      position: absolute;
      top: 17px;
      inset-inline-end: 17px;
      z-index: 1010;
      padding: 0;
      color: white;
      font-weight: 600;
      line-height: 1;
      text-decoration: none;
      background: transparent;
      border-radius: 4px;
      width: 22px;
      height: 22px;
      border: 0;
      outline: 0;
      cursor: pointer;
      transition: color 0.2s, background-color 0.2s;
    }

    &-title {
      margin: 0;
      color: white;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.5;
      word-wrap: break-word;
      text-align: center;
    }
  }
}
