.ant-btn {
  &.end {
    display: block;
    margin-inline-start: auto;
  }

  &.center {
    display: block;
    margin: auto;
  }

  &.ant-btn-default,
  &.ant-btn-primary {
    min-height: 42px;

    &.ant-btn-sm {
      min-height: 32px;
    }
  }

  &.ant-btn-primary {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    box-shadow: none;
    letter-spacing: -0.28px;
  }
}

// TABLE
.ant-table-wrapper {
  .ant-table-container {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }

  .ant-table-thead {
    .ant-table-cell {
      text-transform: capitalize;
      white-space: nowrap;
    }
  }

  // CUSTOM
  &.hide-select-all {
    th.ant-table-selection-column {
      visibility: hidden;
    }
  }
}

// custom radio button card

.ant-radio-button-wrapper.radio-btn-holder {
  border: 1px solid #9999a7;

  &.ant-radio-button-wrapper-checked {
    border: 1px solid #00086d;
    background-color: #eef0ff;
  }
  &::before {
    display: none !important;
  }
}
.custom-radio-group {
  .ant-radio {
    display: none;
  }
  .ant-radio-checked {
    background-color: black;
  }
}

.ant-list-split .ant-list-item {
  border-block-end: none;
}

.ant-list-split .ant-list-header {
  border-block-end: none;
}

.ant-list-split .ant-list-item {
  border-block-end: none;
}

.ant-btn.ant-btn-default,
.ant-btn.ant-btn-primary {
  min-height: 34px;
}

// Modal BG
.ant-modal .ant-modal-content {
  background-color: #fbfbff;
}
.ant-radio-wrapper input[type="radio"]:checked + .ant-radio-inner {
  transform: rotate(180deg);
  transition: transform 0.3s ease; // Add animation transition
}
.table-row-with-gap {
  margin-bottom: 90px;
}
.tabs-card-wrapper {
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-nav-list {
    // width: 100%;

    .ant-tabs-tab {
      flex: 1;
      background-color: white;
      border: none;
      width: 210px;
      margin: 0 !important;
      &.ant-tabs-tab-active {
        color: #00086d;
        // border: solid 1px #00086D;
        border-bottom: none;
        background-color: #eef0ff;
      }
    }
  }
}

.upload-img-border {
  .ant-upload-select {
    width: 100% !important;
    max-width: 200px;
    border: none !important;
  }
  // .ant-tooltip-inner{
  //   display: none !important;
  // }
}
.multi-imgs-upload {
  // .ant-upload-select{
  //   background-color: white !important;
  //  }
  .ant-upload-list-item {
    width: 80px !important;
    height: 80px !important;
    padding: 5px !important;

    img {
      margin: auto;
    }
  }
  .ant-upload-list-item-container {
    width: 80px !important;
    height: 80px !important;
  }
}
.modal-white-bg {
  .ant-modal .ant-modal-content {
    background-color: white !important;
  }
}

.large-checkbox {
  .ant-checkbox-inner {
    width: 20px !important;
    height: 20px !important;
    padding: 10px !important;
  }
  .ant-checkbox-inner::after {
    transform: rotate(45deg) scale(1.2) translate(-30%, -60%);
  }
}
