@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500;600;700&display=swap");

:root {
  --color-primary: #0e18ab;
  --color-display: #00b2f3;
  --color-secondary: #005a7b;
  --color-bg: #f5fcff;
  --color-bg-2: #ebfaff;
  --color-gray: #828385;
  --color-gray-light: #f6f6f6;
  --color-success: #4bb543;
  --color-warn: #faad14;
  --color-danger: #ff4d4f;
  --font-primary: Cairo, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Cairo", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  --swiper-theme-color: #001a62 !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./antd";
@import "./form";
@import "./typography";
@import "./tab";
@import "./table";
@import "./modal";

@layer base {
  html {
    @apply scroll-smooth;
  }

  body {
    // height: 100vh;
    padding: 0;
    margin: 0;
  }
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):active,
.ant-menu-dark>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):active {
  background-color: white;
  color: black;
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover,
.ant-menu-dark>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover {
  background-color: white;
  color: black;
}

.ant-menu-dark .ant-menu-item-selected,
.ant-menu-dark>.ant-menu .ant-menu-item-selected {
  background-color: #F5F5F5;
  color: #000;
  width: 100%;
  margin: 0;
}

.ant-menu-dark .ant-menu-item-selected,
.ant-menu-dark>.ant-menu .ant-menu-item-selected {
  background-color: #F5F5F5;
  color: #000;
  width: 100%;
  margin: 0;
}

.ant-menu-dark>.ant-menu .ant-menu-item .ant-menu-item-active {
  background-color: #F5F5F5;
  color: #000;
  width: 100%;
  margin: 0;
}

.ant-menu .ant-menu-item,
.ant-menu .ant-menu-submenu,
.ant-menu .ant-menu-submenu-title {
  border-radius: 0px;
}

.ant-layout-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: none;
  padding: 0px;
  height: 100;
}

.ant-table-container {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.ant-menu-light .ant-menu-item-selected,
.ant-menu-light>.ant-menu .ant-menu-item-selected {
  color: #fff;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.auth-box-wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .auth-box-holder {
    box-shadow: 0 6px 36px rgba(0, 0, 0, 0.08);
    padding: 30px 40px;
    background-color: #fff;
    border-radius: 16px;
    max-width: 520px;
    min-width: 500px;

    .account-register-row {
      width: 100%;
      text-align: center;
      font-size: 13px;
      font-weight: 500;
      color: #838383;
      margin: 20px auto 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3px;

      a {
        text-decoration: underline;
      }
    }
  }
}

.ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected>th,
.ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected>td,
.ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected:hover>th,
.ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected:hover>td {
  background: #d9d9d9;
}

.table-summary-action {
  padding: 12px;
}

.ant-btn.ant-btn-danger {
  color: #cf2110;
}

.ant-input[disabled] {
  color: #000;
}

.steps-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  .step-content-holder {
    // max-width: 520px;
    min-width: 650px;
    background-color: #fff;
    padding: 30px 40px;
    box-shadow: 0 6px 36px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
  }
}

.upload-img-holder {
  .ant-form-item-control-input-content {
    border: 1px #d9d9d9 dashed;
    max-width: 240px;
    padding: 16px;
    border-radius: 6px;
    margin: auto;
  }
}


:where(.css-dev-only-do-not-override-1sjmm7l).ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
}

:where(.css-dev-only-do-not-override-1sjmm7l).ant-layout .ant-layout-sider {
  position: relative;
  min-width: 0;
  // background-color: #1d1d39;
}

//
@media (max-width: 1000px) {
  .buttonCollaps {
    display: none;
  }

}

.sidebar-toggler .anticon {
  color: white;
}

.ant-layout-sider [class^=ant-layout-sider], 
.ant-layout-sider [class*=" ant-layout-sider"] {
  display: hidden !important;
  color: white;
}

@media (max-width: 1000px) {
  .header-section {
    display: none;
  }

  .sidebar-toggler {
    display: block;
  }
}
.submenu-bottom .ant-menu-submenu-popup {
  left: 0 !important;
  margin-top: 10px;
}


.ant-layout-sider [class^="ant-layout-sider"],

.ant-layout-sider [class*=" ant-layout-sider"] {
  box-sizing: border-box;
}

.ant-menu-light .ant-menu-item-selected,
.ant-menu-light>.ant-menu .ant-menu-item-selected {
  background-color: transparent;
  color: white;
}

.ant-form .ant-form-item .ant-form-item-label label {
  font-weight: 400;
  letter-spacing: -0.28px;
  color: #0E103A;
}

.odd-row {
  background-color: gainsboro;
}

:where(.css-dev-only-do-not-override-1sjmm7l).ant-table-wrapper .ant-table-selection-extra .anticon {
  display: none;
}

.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: white;
}


.ant-layout .ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  bottom: 40px;
  color: #fff;
  line-height: 48px;
  background: white;
  text-align: end;
  cursor: pointer;
}


.ant-menu-dark,
.ant-menu-dark>.ant-menu {
  color: black;
  background: white;
}

.ant-layout .ant-layout-sider-has-trigger {
  padding-bottom: 48px;
  width: 500px;
  background: transparent;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  color: black;

}


.ant-layout .ant-layout-sider-trigger {
  position: relative;
  bottom: 1000px;
  bottom: 0;
  color: black;
  display: none;
  // box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  line-height: 48px;
  background: transparent;
  text-align: end;
  cursor: pointer;
}

.ant-layout .ant-layout-sider-trigger {
  position: relative;
  bottom: 0;
  color: black;
  line-height: 48px;
  background: white;
  text-align: end;
  cursor: pointer;
}



// search input
.ant-input-group>.ant-input:first-child,
.ant-input-group .ant-input-group-addon:first-child {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  padding-bottom: 6px;
}


// Card E-Learning 
.ant-card .ant-card-cover img,
.ant-card .ant-card-cover img+.ant-image-mask {
  border-radius: 0px 0px 0 0;
  padding-top: 4px;
}


.customCard {
  .ant-card .ant-card-body {
    padding: 10px 0px !important;
    border-radius: 0 0 8px 8px;
  }
}


.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark>.ant-menu .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark>.ant-menu .ant-menu-submenu-disabled {
  color: black !important;
}


.ant-form .ant-form-item .ant-input-affix-wrapper,
.ant-form .ant-form-item .ant-input-wrapper {
  border: 1px solid #D9D9D9;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:focus-within {
  border-color: none;
  box-shadow: none;
  outline: 0;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: white !important;
  color: fieldtext !important;
}

input {
  background-color: white !important;

}

//Custom Input Website
.customInputWebsite {

  .ant-input-group>.ant-input:first-child,
  .ant-input-group .ant-input-group-addon:first-child {
    border-start-end-radius: 0;
    border-end-end-radius: 0;
    padding-bottom: 0px;
  }
}

.customInput .ant-form .ant-form-item .ant-input-affix-wrapper,
.ant-form .ant-form-item .ant-input-wrapper {
  border: none;

}

.customExpandedTableEn.dataEmpty .ant-table-wrapper .ant-table-tbody>tr>th,
.customExpandedTableEn.dataEmpty .ant-table-wrapper .ant-table-tbody>tr>td:first-child {
  border-left: 4px solid #FAFAFA !important;
  background: white;
}

.customExpandedTableAr.dataEmpty .ant-table-wrapper .ant-table-tbody>tr>th,
.customExpandedTableAr.dataEmpty .ant-table-wrapper .ant-table-tbody>tr>td:first-child {
  border-left: 4px solid #FAFAFA !important;
  background: white;
}

.spin-loading {
  padding: 50;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4;
}

.ant-spin-dot i {
  background-color: #001529 !important;
}

.spin-loading {
  color: #001529 !important;
}

.ant-spin-text {
  color: #001529 !important;
}

.ant-badge.ant-badge-status .ant-badge-status-dot {
  width: 10px !important;
  height: 10px !important;
}

.ant-btn-variant-solid {
  color: #fff;
  background: #001529;
}

.ant-btn-variant-link {
  color: #001529;
  box-shadow: none;
}

.ant-table-wrapper .ant-table-filter-trigger.active {
  color: #001529;
}

.ant-pagination .ant-pagination-item-active a {
  color: #001529;
}

.ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  background-color: #ffffff;
  border-color: #001529;
}


.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-selected,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title-selected {
  color: #001529 !important;
  background-color: #e6f4ff;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #001529;
  border-color: #001529;
}

.responsive-img {
  width: 100%;
  height: 120px;
  object-fit: contain;
}

.ant-btn-variant-link {
  color: white !important;
  box-shadow: none;
}

//Login

.login-background {
  height: 100vh;
  background-image: url('../assets/Rectangle.png');
  background-size: 432px 100%;
  background-repeat: no-repeat;
  background-position: right;
}

@media (max-width: 768px) {
  .login-background {
    background-image: none;
  }
}
