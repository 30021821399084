.customTab {

  .ant-tabs-nav-list {
    margin: 0 !important;
    text-align: center;
    width: 100%;
    // gap: 32px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .ant-radio-wrapper span {
    padding: 0 !important;
  }

  .ant-tabs-nav-list .ant-tabs-tab {
    flex: 1;
    max-width: calc(100% - 20px);
    /* Adjust margin as needed */
    margin: 5px;
    /* Adjust margin as needed */
  }

  .ant-tabs-tab-btn {
    text-align: center;
  }

  .ant-tabs-ink-bar {
    display: none !important;
  }

  .customTab .ant-tabs .ant-tabs-nav .ant-tabs-tab {
    margin: 0 0 0 0px;
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-operations-hidden,
  .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-operations-hidden {
    position: absolute;
    visibility: hidden;
    display: none;
    pointer-events: none;
  }

  .ant-tabs-nav-list .ant-tabs-tab-active {
    background-color: #eef0ff;
    text-align: center;
  }

  .ant-tabs-nav::before {
    display: none !important;
  }

  .ant-tabs-tab-active>.ant-tabs-tab-btn>* {
    color: #00086d !important;
    text-align: center;
  }

  .tabs {
    background-color: #eef0ff;
    padding: 32px 24px;
    text-align: center;
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
    position: relative;
    display: flex;
    flex: auto;
    align-self: stretch;
    overflow: hidden;
    white-space: nowrap;
    transform: translate(0);
    margin-bottom: 10px;
    text-align: center;
  }

  .customTab .ant-tabs-nav-list .ant-tabs-tab {
    width: 371px;
    height: 47px;
    margin-bottom: 10 !important;
    color: #000000D9 !important;
    text-align: center;
    border-radius: 8px;
    border: 1.768px solid #F0F0F0;
    background: #FAFAFA;
    justify-content: center;
  }

  .ant-tabs .ant-tabs-tab-btn {
    outline: none;
    transition: all 0.3s;
    text-align: center;
  }

  .customTab .ant-tabs-nav-list .ant-tabs-tab-active {
    background-color: #FAFAFA;
    text-align: center;
    color: #0E103A;
    border: 1.768px solid #0E103A;
    justify-content: center;
  }


  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #0E103A;
    text-shadow: 0 0 0.25px currentcolor;
  }

  .customTab .ant-tabs .ant-tabs-tab-btn {
    outline: none;
    transition: all 0.3s;
    text-align: center;
    color: red;
  }
}

.customTab2 {
  .ant-tabs-nav-list {
    margin: 0 !important;
    text-align: center;
  }

  .ant-radio-wrapper span {
    padding: 0 !important;
  }

  .ant-tabs-nav-list {
    text-align: center;
    width: 100%;
    gap: 26px;
    text-align: center;
  }

  .ant-tabs-ink-bar {
    display: none !important;
  }

  .customTab .ant-tabs .ant-tabs-nav .ant-tabs-tab {
    margin: 0 0 0 0px;
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-operations-hidden,
  .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-operations-hidden {
    position: absolute;
    visibility: hidden;
    display: none;
    pointer-events: none;
  }

  .ant-tabs-nav-list .ant-tabs-tab-active {
    background-color: #eef0ff;
    text-align: center;
  }

  .ant-tabs-nav::before {
    display: none !important;
  }

  .ant-tabs-tab-active>.ant-tabs-tab-btn>* {
    color: #00086d !important;
    text-align: center;
  }

  .tabs {
    background-color: #eef0ff;
    padding: 32px 24px;
    text-align: center;
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
    position: relative;
    display: flex;
    flex: auto;
    align-self: stretch;
    overflow: hidden;
    white-space: nowrap;
    transform: translate(0);
    margin-bottom: 10px;
    text-align: center;
  }

  .customTab .ant-tabs-nav-list .ant-tabs-tab {
    width: 549px;
    height: 47px;
    margin-bottom: 10 !important;
    color: #000000D9 !important;
    text-align: center;
    border-radius: 8px;
    border: 1.768px solid #F0F0F0;
    background: #FAFAFA;
    justify-content: center;
  }

  .ant-tabs .ant-tabs-tab-btn {
    outline: none;
    transition: all 0.3s;
    text-align: center;
  }

  .customTab .ant-tabs-nav-list .ant-tabs-tab-active {
    background-color: #FAFAFA;
    text-align: center;
    color: #0E103A;
    border: 1.768px solid #0E103A;
    justify-content: center;
  }


  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #0E103A;
    text-shadow: 0 0 0.25px currentcolor;
  }

  .customTab .ant-tabs .ant-tabs-tab-btn {
    outline: none;
    transition: all 0.3s;
    text-align: center;
    color: red;
  }
}




// Tabs

.ant-card:not(.ant-card-bordered) {
  box-shadow: none;
}

.ant-tabs-top>.ant-tabs-nav::before,
.ant-tabs-bottom>.ant-tabs-nav::before,
.ant-tabs-top>div>.ant-tabs-nav::before,
.ant-tabs-bottom>div>.ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: none;
  // display: none;
  content: '';
}


.ant-tabs>.ant-tabs-nav .ant-tabs-nav-more,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-more {
  position: absolute;
  padding: 0px;
  background: transparent;
  display: none;
  border: 0;
  color: rgba(0, 0, 0, 0.88);
}

.ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-nav-wrap::after {
  top: 0;
  bottom: 0;
  width: 32px;
  display: none;
}
