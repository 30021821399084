.customTableEn {

    // Bg for head
    .ant-table-wrapper .ant-table-thead>tr>th,
    .ant-table-wrapper .ant-table-thead>tr>td {
        position: relative;
        color: #020418;
        font-weight: 600;
        text-align: start;
        background: white;
        border-bottom: 1px solid #f0f0f0;
        margin-bottom: 10px;
        transition: background 0.2s ease;
    }

    // 
    .ant-table-cell::before {
        display: none !important;
    }

    .ant-table-wrapper .ant-table-container {
        box-shadow: none;
    }

    .ant-table-container {
        box-shadow: none;
    }

    .ant-table-wrapper tr.ant-table-expanded-row>th,
    .ant-table-wrapper tr.ant-table-expanded-row:hover>th,
    .ant-table-wrapper tr.ant-table-expanded-row>td,
    .ant-table-wrapper tr.ant-table-expanded-row:hover>td {
        bottom: 15px;
        background: red;
    }

    .ant-table-wrapper tr.ant-table-expanded-row>th,
    .ant-table-wrapper tr.ant-table-expanded-row:hover>th,
    .ant-table-wrapper tr.ant-table-expanded-row>td,
    .ant-table-wrapper tr.ant-table-expanded-row:hover>td {
        background: white;
    }

    // border top and bottom for all row 
    .ant-table-cell {
        font-size: 14px !important;
        border-bottom: 4px solid #FAFAFA !important;
        border-top: 4px solid #FAFAFA !important;
    }

    // border left and right for row in head 
    .ant-table-thead>tr>th:first-child {
        border-left: 4px solid #FAFAFA !important;
        border-top: 4px solid #FAFAFA !important;
    }

    .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
        border-start-end-radius: 0px;
        border-right: 4px solid #FAFAFA !important;
    }


    // border left and right for row in body 
    .ant-table-wrapper .ant-table-tbody>tr>th,
    .ant-table-wrapper .ant-table-tbody>tr>td:first-child {
        transition: background 0.2s, border-color 0.2s;
        border-left: 4px solid #FAFAFA !important;
    }

    .ant-table-wrapper .ant-table-tbody>tr>th,
    .ant-table-wrapper .ant-table-tbody>tr>td:last-child {
        transition: background 0.2s, border-color 0.2s;
        border-right: 4px solid #FAFAFA !important;
    }

    .ant-table table {
        border-spacing: 0 8px;
    }

    .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
        border-start-start-radius: 0px;
        border-left: 4px solid #FAFAFA !important;
    }
}



.customTableAr {

    // Bg for head
    .ant-table-wrapper .ant-table-thead>tr>th,
    .ant-table-wrapper .ant-table-thead>tr>td {
        position: relative;
        color: #020418;
        font-weight: 600;
        text-align: start;
        background: white;
        border-bottom: 1px solid #f0f0f0;
        margin-bottom: 10px;
        transition: background 0.2s ease;
    }

    .ant-table-wrapper tr.ant-table-expanded-row>th,
    .ant-table-wrapper tr.ant-table-expanded-row:hover>th,
    .ant-table-wrapper tr.ant-table-expanded-row>td,
    .ant-table-wrapper tr.ant-table-expanded-row:hover>td {
        background: white;
    }

    // 
    .ant-table-cell::before {
        display: none !important;
    }

    .ant-table-wrapper .ant-table-container {
        box-shadow: none;
    }

    .ant-table-container {
        box-shadow: none;
    }

    .ant-table-wrapper tr.ant-table-expanded-row>th,
    .ant-table-wrapper tr.ant-table-expanded-row:hover>th,
    .ant-table-wrapper tr.ant-table-expanded-row>td,
    .ant-table-wrapper tr.ant-table-expanded-row:hover>td {
        bottom: 8px;
    }

    // border top and bottom for all row 
    .ant-table-cell {
        font-size: 14px !important;
        border-bottom: 4px solid #FAFAFA !important;
        border-top: 4px solid #FAFAFA !important;
    }

    // border left and right for row in head 
    .ant-table-thead>tr>th:first-child {
        border-right: 4px solid #FAFAFA !important;
        border-top: 4px solid #FAFAFA !important;
    }

    .ant-table-wrapper .ant-table-row-expand-icon-cell .ant-table-thead>tr>th:first-child {
        text-align: center;
        border-right: 4px solid #FAFAFA !important;
        border-top: 4px solid #FAFAFA !important;
    }

    .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
        border-start-end-radius: 0px;
        border-left: 4px solid #FAFAFA !important;
    }


    // border left and right for row in body 
    .ant-table-wrapper .ant-table-tbody>tr>th,
    .ant-table-wrapper .ant-table-tbody>tr>td:first-child {
        transition: background 0.2s, border-color 0.2s;
        border-right: 4px solid #FAFAFA !important;
    }

    .ant-table-wrapper .ant-table-tbody>tr>th,
    .ant-table-wrapper .ant-table-tbody>tr>td:last-child {
        transition: background 0.2s, border-color 0.2s;
        border-left: 4px solid #FAFAFA !important;
    }

    .ant-table table {
        border-spacing: 0 8px;
    }

    .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
        border-start-start-radius: 0px;
        border-right: 4px solid #FAFAFA !important;
    }
}

.customTableRow {
    background-color: white;
}

// Exam Table
.custom-table {
    .ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container {
        border-inline-start: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
    }

    .ant-table-wrapper .ant-table-container {
        border-start-start-radius: 0px;
        border-start-end-radius: 0px;
    }

    .ant-table-container {
        box-shadow: none;
    }

    .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
        border-start-end-radius: 0px;
    }
}


// 



.customExpandedTableEn {

    // Bg for head
    .ant-table-wrapper .ant-table-thead>tr>th,
    .ant-table-wrapper .ant-table-thead>tr>td {
        position: relative;
        color: #020418;
        font-weight: 600;
        text-align: start;
        background: white;
        border-bottom: 1px solid #f0f0f0;
        margin-bottom: 10px;
        transition: background 0.2s ease;
    }

    // 
    .ant-table-cell::before {
        display: none !important;
    }

    .ant-table-wrapper .ant-table-container {
        box-shadow: none;
    }

    .ant-table-container {
        box-shadow: none;
    }

    .ant-table-wrapper tr.ant-table-expanded-row>th,
    .ant-table-wrapper tr.ant-table-expanded-row:hover>th,
    .ant-table-wrapper tr.ant-table-expanded-row>td,
    .ant-table-wrapper tr.ant-table-expanded-row:hover>td {
        bottom: 15px;
        background: red;
    }

    .ant-table-wrapper tr.ant-table-expanded-row>th,
    .ant-table-wrapper tr.ant-table-expanded-row:hover>th,
    .ant-table-wrapper tr.ant-table-expanded-row>td,
    .ant-table-wrapper tr.ant-table-expanded-row:hover>td {
        background: white !important;
    }

    // border top and bottom for all row 
    .ant-table-cell {
        font-size: 14px !important;
        border-bottom: 4px solid #FAFAFA !important;
        border-top: 4px solid #FAFAFA !important;
    }

    // border left and right for row in head 
    .ant-table-thead>tr>th:first-child {
        border-left: 4px solid #FAFAFA !important;
        border-top: 4px solid #FAFAFA !important;
    }

    .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
        border-start-end-radius: 0px;
        border-right: 4px solid #FAFAFA !important;
    }


    // border left and right for row in body 
    .ant-table-wrapper .ant-table-tbody>tr>th,
    .ant-table-wrapper .ant-table-tbody>tr>td:first-child {
        transition: background 0.2s, border-color 0.2s;
        border-left: 4px solid #FAFAFA !important;
        background: #F4F4F4;
    }

    .ant-table-wrapper .ant-table-tbody>tr>th,
    .ant-table-wrapper .ant-table-tbody>tr>td:last-child {
        transition: background 0.2s, border-color 0.2s;
        border-right: 4px solid #FAFAFA !important;

    }

    .ant-table table {
        border-spacing: 0 8px;
    }

    .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
        border-start-start-radius: 0px;
        border-left: 4px solid #FAFAFA !important;
    }
}

.customExpandedTableAr {

    // Bg for head
    .ant-table-wrapper .ant-table-thead>tr>th,
    .ant-table-wrapper .ant-table-thead>tr>td {
        position: relative;
        color: #020418;
        font-weight: 600;
        text-align: start;
        background: white;
        border-bottom: 1px solid #f0f0f0;
        margin-bottom: 10px;
        transition: background 0.2s ease;
    }

    .ant-table-wrapper tr.ant-table-expanded-row>th,
    .ant-table-wrapper tr.ant-table-expanded-row:hover>th,
    .ant-table-wrapper tr.ant-table-expanded-row>td,
    .ant-table-wrapper tr.ant-table-expanded-row:hover>td {
        background: white !important;
    }

    // 
    .ant-table-cell::before {
        display: none !important;
    }

    .ant-table-wrapper .ant-table-container {
        box-shadow: none;
    }

    .ant-table-container {
        box-shadow: none;
    }

    .ant-table-wrapper tr.ant-table-expanded-row>th,
    .ant-table-wrapper tr.ant-table-expanded-row:hover>th,
    .ant-table-wrapper tr.ant-table-expanded-row>td,
    .ant-table-wrapper tr.ant-table-expanded-row:hover>td {
        bottom: 8px;
    }

    // border top and bottom for all row 
    .ant-table-cell {
        font-size: 14px !important;
        border-bottom: 4px solid #FAFAFA !important;
        border-top: 4px solid #FAFAFA !important;
    }

    // border left and right for row in head 
    .ant-table-thead>tr>th:first-child {
        border-right: 4px solid #FAFAFA !important;
        border-top: 4px solid #FAFAFA !important;
    }

    .ant-table-wrapper .ant-table-row-expand-icon-cell .ant-table-thead>tr>th:first-child {
        text-align: center;
        border-right: 4px solid #FAFAFA !important;
        border-top: 4px solid #FAFAFA !important;
    }

    .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
        border-start-end-radius: 0px;
        border-left: 4px solid #FAFAFA !important;
    }


    // border left and right for row in body 
    .ant-table-wrapper .ant-table-tbody>tr>th,
    .ant-table-wrapper .ant-table-tbody>tr>td:first-child {
        transition: background 0.2s, border-color 0.2s;
        border-right: 4px solid #FAFAFA !important;
        background: #F4F4F4;

    }

    .ant-table-wrapper .ant-table-tbody>tr>th,
    .ant-table-wrapper .ant-table-tbody>tr>td:last-child {
        transition: background 0.2s, border-color 0.2s;
        border-left: 4px solid #FAFAFA !important;
    }

    .ant-table table {
        border-spacing: 0 8px;
    }

    .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
        border-start-start-radius: 0px;
        border-right: 4px solid #FAFAFA !important;
    }
}