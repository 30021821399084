.col-2 {
  @apply grid grid-cols-1 lg:grid-cols-2 items-end gap-x-4;
}
.col-3 {
  @apply grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 items-end gap-x-4;
}
.col-4 {
  @apply grid grid-cols-2 md:grid-cols-4 items-end gap-x-4;
}
.col-5 {
  @apply grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 items-end gap-x-4;
}
.col-6 {
  @apply grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 items-end gap-x-4;
}
.col-7 {
  @apply grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-7 items-end gap-x-4;
}



.upload-no-style-wrapper {
  display: block;
  width: 100%;
  .upload-no-style {
    background: transparent;
    border: none;
  }
}

// HEADINGS
.title-32 {
  font-weight: 700;
  font-size: 32px;
  color: #424242;
}
.title-28 {
  font-weight: 700;
  font-size: 28px;
  color: #424242;
}
.title-24 {
  font-weight: 700;
  font-size: 24px;
  color: #424242;
}
.title-20 {
  font-weight: 600;
  font-size: 20px;
  color: #424242;
}
.title-18 {
  font-weight: 500;
  font-size: 18px;
  color: #424242;
}
