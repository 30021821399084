.ant-picker {
  width: 100%;
}

.ant-form .ant-form-item {
  margin-bottom: 32px;
  .ant-form-item-label {
    padding-bottom: 16px;
    label {
      font-weight: 400;
      letter-spacing: -0.28px;
    }
  }
  .ant-input-affix-wrapper,
  .ant-input-wrapper {
    border: 0.8px solid #00086d;
  }
}

.custom-checkbox {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  border: 0.8px solid #9999a7;
  border-radius: 12px;
  padding: 12px 24px;
  cursor: pointer;

  &.checked {
    background-color: var(--color-primary);

    & span {
      color: white;
    }

    & svg path {
      color: white;
    }
  }
  @media (max-width: 768px) {
    & svg {
      max-width: 16px;
      max-height: 16px;
    }
  }
  &.checked {
    svg path {
      fill: white; // Change the fill color of the SVG when the checkbox is checked
    }
  }

  input[type="checkbox"]:checked + & {
    background-color: blueviolet !important; // Change the background color when checkbox is checked
  }
}

.PhoneInput {
  // width: 100%;
  // height: 44px;
  padding: 7px 16px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  // font-size: 14px;
  font-weight: 400;
  // direction: ltr;
  input {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    padding-inline-start: 10px;

    &:focus-visible {
      outline: 0;
    }
  }
  .PhoneInputCountry {
    gap: 8px;
  }
}
.ant-radio-button:hover {
  background: #eef0ff !important;
  border-radius: 5%;
}
.ant-radio-button-checked {
  background: #eef0ff !important;
  border-radius: 5%;
}
.ant-input-affix-wrapper,
.ant-select-selector,
.ant-picker {
  input {
    &::-webkit-input-placeholder {
      font-size: 14px;
      letter-spacing: -0.28px;
    }

    &:-ms-input-placeholder {
      font-size: 14px;
      letter-spacing: -0.28px;
    }

    &::placeholder {
      font-size: 14px;
      letter-spacing: -0.28px;
    }
  }
}
.ant-input-affix-wrapper-status-error {
  .ant-input-suffix {
    display: none;
  }
}
.ant-input-affix-wrapper-focused {
  &.ant-input-affix-wrapper-status-error {
    .ant-input-suffix {
      display: block;
    }
  }
}
