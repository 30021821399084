.sidebar {
  background: white;
  box-shadow: 0px 2.929px 11.716px 0px rgba(0, 0, 0, 0.15);
  height: 75vh;
  padding: 0;
}

.menuItem {
  height: 45px;
  display: flex;
  align-items: center;
  color: black;
}

.buttonCollaps {
  position: absolute;
  top: 180px;
}

.collapsed .collapseButton,
.expanded .collapseButton {
  font-size: 16px;
  width: 14px;
  height: 64px;
  border: none;
  background: transparent;
  margin-top: 20px;
}

.icon {
  color: white;
}

.ant-menu-inline>.ant-menu-item,
.ant-menu-vertical>.ant-menu-item,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  color: black;
}

.ant-menu-inline>.ant-menu-item,
.ant-menu-vertical>.ant-menu-item,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  color: black;
}